import { Dialog } from 'components/primitives/Dialog'
import { ToastContext } from 'context/ToastContextProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { Button, CheckBox, Flex, Text } from './primitives'

const useLocalStorage = (keyName: string, defaultValue: string) => {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const value = window.localStorage.getItem(keyName)

      if (value) {
        return JSON.parse(value)
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (err) {
      return defaultValue
    }
  })

  const setValue = (newValue: string) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue))
    } catch (err) {}
    setStoredValue(newValue)
  }

  return [storedValue, setValue]
}

const TermsModal = () => {
  const account = useAccount()
  const [agree, setAgree] = useLocalStorage('terms', 'false')
  const [open, setOpen] = useState(false)
  const { addToast } = useContext(ToastContext)

  useEffect(() => {
    if (account.address && agree == 'false') {
      setOpen(true)
    }
  }, [account, agree])

  return (
    <Dialog
      open={open}
      onOpenChange={() => {}}
      overlayProps={{ style: { opacity: 0.7 } }}
    >
      <div style={{ padding: '1rem' }}>
        <h1
          style={{
            fontSize: '1.2rem',
            paddingBottom: '20px',
          }}
        >
          Welcome to Birka
        </h1>

        <p>
          By using Birka, you agree to the{' '}
          <a
            href="https://www.notion.so/beyondclub/Birka-Terms-of-Use-eac8e2c759c14730bba910d712dbd617?pvs=4"
            target="_BLANK"
            rel="noreferrer noopener"
          >
            Terms of Service
          </a>
        </p>

        <Flex direction="row" css={{ pt: 24, gap: '$2' }}>
          <CheckBox
            checked={agree == 'true' ? true : false}
            onCheckedChange={(checked) => {
              if (agree == 'true') setAgree('false')
              else setAgree('true')
            }}
          />
          Agree{' '}
        </Flex>

        <div
          style={{
            display: 'flex',
            marginTop: 25,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            size="medium"
            disabled={agree == 'true' ? false : true}
            onClick={() => {
              if (agree) {
                setOpen(false)
              } else {
                addToast?.({
                  title: 'Denied',
                  description:
                    'You need to accept the terms to use our service',
                })
              }
            }}
          >
            <Text style="subtitle2" css={{ color: 'inherit' }}>
              Ok
            </Text>
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default TermsModal
